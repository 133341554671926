
import { Component, Vue } from "vue-property-decorator";
import Pagination from "@/components/Pagination.vue";
import { PagedResultOfUserViewModelDto, RoleListViewModel } from "@/api-client";
import { rolesDdlGet, usersGet } from "@/api/users";
import { handleQueries } from "@/utils/handleQueries"
import { debounce } from 'vue-debounce'

@Component({
  name: "UserList",
  components: {
    Pagination,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'danger',
        true: 'success',
        null: 'success'
      }
      return statusMap[status]
    }
  }
})
export default class extends Vue {
  tableData: PagedResultOfUserViewModelDto = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  search: string = "";
  roleFilter = [];
  pageNumber = 1;
  pageSize = 20;
  rolesDdl: RoleListViewModel[] = [];
  dFn = debounce((val:Function) => val(), '600ms')

  get params() {
    return {
      search: this.search,
      pageNumber: this.pageNumber.toString(),
      pageSize: this.pageSize.toString(),
      roleFilter: this.roleFilter
    }
  }

  async created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      roleFilter: this.roleFilter
    }
    this.setQueryWatch(toQuery)

    this.rolesDdl = await rolesDdlGet();
    this.loadInitialData();
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        (this as any)[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleRoleFilter() {
    this.pageNumber = 1;
    this.loadInitialData();
  }

  handleSearch() {
    // if(isClear || this.search.length >= 3) {
    //   this.dFn(() => {
        this.pageNumber = 1;
        this.loadInitialData().then(() => {
          setTimeout(() => {
            document.getElementById("search")?.focus();
          }, 0);
        })
    //   })
    // }
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handlePageSizeChange(size: number) {
    this.pageNumber = 1;
    this.pageSize = size;
    this.loadInitialData()
  }

  displayRoles(ids: string[]) {
    if (ids?.length && this.rolesDdl.length) {
      let roles: string[] = [];
      ids.forEach((id) => {
        const role = this.rolesDdl.find((item) => item.id == id);
        if(role) {
          roles.push(role.name);
        }
      })
      return roles.sort().join(", ");
    } else {
      return "";
    }
  }

  async loadInitialData() {
    await usersGet(this.pageNumber, this.pageSize, this.search || undefined, this.roleFilter || undefined)
    .then(async (res) => {
      this.tableData = res;
    })
  }
}
